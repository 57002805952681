import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BlurService } from './core/services/blur.service';
import { LoadingService } from './core/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  blurBg: boolean = false
  title = 'Portal Daycoval'
  exibeFooter: boolean = true
  exibeHeader: boolean = true
  positionFixed: boolean = false
  blackListFooter: string[]
  blackListHeader: string[]
  fixedHeaderComponents: string[]
  subscriptionBlur: Subscription
  subscriptionLoading: Subscription
  calculadoras: string
  currentPage: string
  currentPageBool: boolean = false

  constructor(
    private router: Router,
    private _blurService: BlurService,
    private _loadingService: LoadingService,
    private spinner: NgxSpinnerService,
    private _elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute('ng-version');
    if (window.location.href.indexOf('bancodaycoval.com.br') > 0)
      window.open('https://www.daycoval.com.br', '_self')

    this.setBlacklistFooter()
    this.setBlacklistHeader()
    this.setFixedHeaderComponents()

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentPage = event.url;
      if (this.currentPage != '/cet' &&
      this.currentPage != '/espelho' && this.currentPage !='/institucional/cet' && this.currentPage!= '/institucional/espelho')
      {
        this.currentPageBool = false;
      }
      else {
        this.currentPageBool = true;
      }
    });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
        let url: string = event['url']
        this.exibeFooter = this.verificarSeExibeFooter(url)
        this.exibeHeader = this.verificarSeExibeHeader(url)
        this.positionFixed = this.ifPositionFixed(url)
      }
    });

    this.subscriptionBlur = this._blurService
      .getBlurBackground()
      .subscribe((sub) => {
        this.blurBg = sub;
      });

    this.subscriptionLoading = this._loadingService
      .obterStatusLoading()
      .subscribe((sub) => {
        sub ? this.spinner.show() : this.spinner.hide()
      });
  }

  ngOnDestroy() {
    this.subscriptionBlur.unsubscribe();
    this.subscriptionLoading.unsubscribe();
  }

  verificarSeExibeFooter(url: string) {
    let show: boolean =
      this.blackListFooter.find((x) => x == url) == undefined &&
      url.indexOf('registrar-nova-solicitacao') < 0 &&
      url.indexOf('atendimentodayane') < 0;

    return show;
  }

  verificarSeExibeHeader(url: string) {
    return this.blackListHeader.find((x) => x == url) == undefined;
  }

  ifPositionFixed(url: string) {
    if (url != undefined)
      return this.fixedHeaderComponents.includes(url);
    else
      return false
  }

  setBlacklistFooter() {
    this.blackListFooter = [
      '/investimentos/renda-fixa/comparar',
      '/investimentos/renda-fixa/simulador/comparar',
      '/investimentos/renda-fixa/simulador',
      '/credito-para-sua-empresa/investimentos/renda-fixa/comparar',
      '/investimentos/fundos-investimento/comparar',
      '/investimentos/comparar',
      '/credito-para-sua-empresa/investimentos/fundos-investimento/comparar',
      '/atendimento/acompanhe-seu-atendimento/detalhes',
      '/investimentos/mercado-capitais/informacoes-cotista/detalhes',
      '/investimentos/mercado-capitais/ofertas/detalhes',
      '/redirect',
      '/dayane',
      '/nps',
      '/atendimentodayane',
      '/campanhadaycoval',
      '/investimentos/mercado-capitais/informe-rendimentos/detalhes',
      '/calculadoras/calculadora-do-milhao'
    ]
  }

  setBlacklistHeader() {
    this.blackListHeader = [
      '/dayane',
      '/calculadoras/calculadora-do-milhao'
    ]
  }

  setFixedHeaderComponents() {
    //Páginas com header fixado com sticky. Esta função aplica a margem necessária.
    this.fixedHeaderComponents = [
      '/investimentos/renda-fixa/comparar',
      '/investimentos/renda-fixa/simulador/comparar',
      '/investimentos/fundos-investimento/comparar'
    ]
  }


}
