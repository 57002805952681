<ng-container *ngIf="!currentPageBool">
<section class="footer bg-white">
  <div class="container py-3 pt-sm-0">
    <div class="d-flex justify-content-between flex-sm-row flex-column row">
      <div class="col-box-menu col-12 col-md-2 my-lg-2 my-md-0 px-3 px-sm-0" *ngFor="let coluna of colunas; let j = index">
        <div class="box-menu-footer mb-lg-5 pt-3 pt-lg-0" *ngFor="let menu of coluna.menus; let i = index">
          <a href="#List-{{j}}-{{i}}" data-toggle="collapse" class="collapsed pb-3 pb-lg-0">
            <p class="titulos-footer">{{menu.Titulo}}</p>
            <span class="material-icons d-md-none">
              expand_more
            </span>
          </a>
          <div id="List-{{j}}-{{i}}" class="collapse d-md-block mt-3 mt-md-0">
            <ul>
              <li *ngFor="let link of menu.Item">
                <a *ngIf="link.EhLinkExterno" href="{{link.Link}}" target="_blank">{{link.Descricao}}</a>
                <a *ngIf="!link.EhLinkExterno" href="{{link.Link}}">{{link.Descricao}}</a>
                <hr class="d-md-none">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</ng-container>
<app-footer-contatos></app-footer-contatos>

