import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { LojasHelper, RotaLojasEnum, urlAgencias } from 'src/app/core/helpers/lojas.helper';
import { Link } from 'src/app/core/models/link.model';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

  @Input() menuAtivo: { titulo: string, descricao: string, link: string, index: number }
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter();
  @Output() closeSubmenu: EventEmitter<boolean> = new EventEmitter();

  quebraColuna: number = 7;
  submenus: { indexMenu: number; submenu: { link: Link; icone: string }[] }[] = [];
  submenuAtivo?: { link: Link; icone: string }[] = [];

  constructor() { }

  ngOnInit(): void {
    this.getSubmenus();
    if (this.menuAtivo) {
      this.submenuAtivo = this.submenus.find(x => x.indexMenu == this.menuAtivo.index)?.submenu
      /*if (this.submenuAtivo) {
        this.quebraColuna = this.submenuAtivo.length / 2
      }*/
    }
  }
  fecharMenu() {
    this.closeMenu.emit(false);
  }

  fecharSubmenu() {
    this.closeSubmenu.emit(false);
  }

  getSubmenus() {
    let path = 'assets/img/icons/menu/';
    this.submenus = [
      {
        indexMenu: 0,
        submenu: [
          {
            link: new Link('Sobre nós', '/institucional/sobre-nos'),
            icone: path + 'sobre-nos.svg'
          },
          {
            link: new Link('Sustentabilidade', '/sustentabilidade'),
            icone: path + 'sustentabilidade.svg'
          },
          {
            link: new Link('Trabalhe Conosco', '/institucional/trabalhe-conosco'),
            icone: path + 'trabalhe-conosco.svg'
          },
          {
            link: new Link('Imprensa', '/institucional/noticias-e-imprensa'),
            icone: path + 'noticias.svg'
          },
          {
            link: new Link('Relações com Investidores', LinksUteisEnum.RI, true),
            icone: path + 'relacoes-investidores.svg'
          },
          {
            link: new Link('Ouvidoria', '/institucional/ouvidoria'),
            icone: path + 'ouvidoria.svg'
          },
          {
            link: new Link('Agências e Correspondentes', urlAgencias),
            icone: path + 'correspondentes.svg'
          },
          {
            link: new Link('Blog', LinksUteisEnum.BLOG, true),
            icone: path + 'blog.svg'
          },
          {
            link: new Link('Segurança e Prevenção', 'institucional/seguranca-e-prevencao-a-fraude'),
            icone: path + 'seguranca.svg'
          },
          {
            link: new Link('Políticas de Privacidade', LinksUteisEnum.RIPOLITICAPRIVACIDADE, true),
            icone: path + 'privacidade.svg'
          },
          {
            link: new Link('Acessibilidade', 'institucional/acessibilidade'),
            icone: path + 'acessibilidade.svg'
          },
          {
            link: new Link('Revista Day by Day', 'institucional/flipbook-day-by-day'),
            icone: path + 'literatura.svg'
          },
        ]
      },
      {
        indexMenu: 1,
        submenu: [
          {
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado'),
            icone: path + 'credito-consignado.svg'
          },
          {
            link: new Link('Crédito em Conta', '/credito-para-voce/credito-em-conta'),
            icone: path + 'credito-em-conta.svg'
          },
          {
            link: new Link('Crédito Imobiliário', '/credito-imobiliario'),
            icone: path + 'credito-imobiliario.svg'
          },
          {
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito'),
            icone: path + 'cartao-credito.svg'
          },
          {
            link: new Link('Financiamento de Veículos', '/credito-para-voce/veiculos'),
            icone: path + 'financiamento-veiculo.svg'
          },
          {
            link: new Link('FGTS Antecipado Daycoval', '/credito-para-voce/fgts-antecipado'),
            icone: path + 'fgts-antecipado.svg'
          }
        ]
      },
      {
        indexMenu: 2,
        submenu: [
          {
            link: new Link('Conta para sua Empresa', '/credito-para-sua-empresa/conta-empresa'),
            icone: path + 'empresa.svg'
          },
          {
            link: new Link('Crédito Digital', '/credito-para-sua-empresa/creditos/credito-digital'),
            icone: path + 'credito-digital.svg'
          },
          {
            link: new Link('Modalidades de Crédito', '/credito-para-sua-empresa/creditos/operacoes-credito'),
            icone: path + 'modalidades.svg'
          },
          {
            link: new Link('Cartão de Crédito', '/credito-para-sua-empresa/cartao-corporate'),
            icone: path + 'cartao-credito.svg'
          },
          {
            link: new Link('Serviços para Empresas', '/servicos/servicos-empresa'),
            icone: path + 'servicos.svg'
          },
          {
            link: new Link('Seguros para Empresas', '/servicos/seguros-empresa'),
            icone: path + 'seguros.svg'
          },
          {
            link: new Link('Debt Capital Markets | DCM', '/credito-para-sua-empresa/debt-capital-markets'),
            icone: `${path}mercado-de-capitais.svg`
          }
        ]
      },
      {
        indexMenu: 3,
        submenu: [
          {
            link: new Link('Daycoval Investe', '/investimentos/daycoval-investe'),
            icone: path + 'daycoval-digital.svg'
          },
          {
            link: new Link('Simulador de Investimentos', '/investimentos/simulador-investimentos'),
            icone: path + 'simulador-investimentos.svg'
          },
          {
            link: new Link('Renda Fixa', '/investimentos/renda-fixa'),
            icone: path + 'renda-fixa.svg'
          },
          {
            link: new Link('Fundos de Investimento', '/fundos'),
            icone: path + 'fundos-investimento.svg'
          },
          {
            link: new Link('Renda Variável', '/investimentos/renda-variavel'),
            icone: path + 'renda-variavel.svg'
          },
          {
            link: new Link('Corretora CTVM', LinksUteisEnum.CORRETORACTVM, true),
            icone: path + 'corretora-ctvm.svg'
          },
          {
            link: new Link('Previdência', '/investimentos/previdencia'),
            icone: path + 'previdencia.svg'
          },
          {
            link: new Link('Indique um Amigo', LinksUteisEnum.CAMPANHASDAYCOVAL, true),
            icone: path + 'indique-um-amigo.svg'
          },
          {
            link: new Link('Plataforma Aberta', '/investimentos/plataforma-aberta'),
            icone: path + 'plataforma-aberta.svg'
          },
          {
            link: new Link('Serviços ao Mercado de Capitais', '/investimentos/mercado-capitais'),
            icone: `${path}mercado-capitais.svg`
          },
          {
            link: new Link('Daycoval Asset Management', '/asset', true),
            icone: path + 'logo.svg'
          },
          {
            link: new Link('Relatórios de Investimentos', '/investimentos/relatorios-investimentos'),
            icone: path + 'documento.svg'
          },
          {
            link: new Link('Transferência de Custódia', '/investimentos/transferencia-custodia'),
            icone: path + 'transferencia-ativos.svg'
          }
        ]
      }, {

        indexMenu: 4,
        submenu: [
          {
            link: new Link('Quero Comprar', '/cambio/simulador'),
            icone: path + 'quero-comprar.svg'
          },
          {
            link: new Link('Moeda em Espécie', '/cambio/moedas'),
            icone: path + 'moeda-especie.svg'
          },
          {
            link: new Link('Cartão de Viagem', '/cambio/cartao-viagem'),
            icone: path + 'cartao-viagem.svg'
          },
          {
            link: new Link('Remessas Internacionais', '/cambio/remessas-internacionais'),
            icone: path + 'remessa-internacional.svg'
          },
          {
            link: new Link('Compra de Cheques', '/cambio/compra-cheques'),
            icone: path + 'compra-cheque.svg'
          },
          {
            link: new Link('DayPay', '/cambio/daypay'),
            icone: path + 'daypay.svg'
          },
          {
            link: new Link('MoneyGram', '/cambio/moneygram'),
            icone: path + 'moneygram.svg'
          },
          {
            link: new Link('Cartão de Viagem Corporativo', '/cambio/cartao-viagem-corporativo'),
            icone: path + 'cartao-viagem.svg'
          },
          {
            link: new Link('Seguro Viagem', LinksUteisEnum.SEGUROVIAGEM, true),
            icone: path + 'seguro-viagem.svg'
          },
          {
            link: new Link('Encontre uma loja', LojasHelper.obterRotaPorRotaLojaEnum(RotaLojasEnum.CAMBIO)),
            icone: path + 'encontre-loja.svg'
          },
          {
            link: new Link('Outros Serviços', '/cambio/outros-servicos'),
            icone: path + 'outros-servicos.svg'
          },
          {
            link: new Link('Área Restrita para Agentes', LinksUteisEnum.AREARESTRITACAMBIO, true),
            icone: path + 'area-restritra.svg'
          },
          {
            link: new Link('Novo Marco Cambial', '/cambio/marco-cambial'),
            icone: path + 'exchange-money.svg'
          }
        ]
      },
      {
        indexMenu: 5,
        submenu: [
          {
            link: new Link('Derivativos', '/tesouraria/derivativos'),
            icone: `${path}derivativos.svg`
          }
        ]
      },
      {
        indexMenu: 6,
        submenu: [
          {
            link: new Link('Internet Banking - Dayconnect', '/servicos/internet-banking'),
            icone: path + 'internet-banking.svg'
          },
          {
            link: new Link('Cadastro Positivo', '/servicos/cadastro-positivo'),
            icone: path + 'cadastro-positivo.svg'
          },
          {
            link: new Link('Daypag - Despachantes', '/servicos/daypag'),
            icone: path + 'daypag.svg'
          },
          {
            link: new Link('Serviços para Empresas', '/servicos/servicos-empresa'),
            icone: path + 'servicos.svg'
          },
          {
            link: new Link('Seguros para Empresas', '/servicos/seguros-empresa'),
            icone: path + 'seguros.svg'
          },
          {
            link: new Link('Cotação do Dólar Cartão de Crédito', '/servicos/cotacao-dolar'),
            icone: path + 'cotacao-dollar.svg'
          },
          {
            link: new Link('Cheque Legal', '/servicos/cheque-legal'),
            icone: path + 'cheque-legal.svg'
          },
          {
            link: new Link('Tarifas', '/servicos/tarifas'),
            icone: path + 'tarifas.svg'
          },
          {
            link: new Link('Canal do Correspondente', 'https://www.daycoval.com.br/canalcorrespondente/login', true),
            icone: path + 'area-restritra.svg'
          }
        ]
      }
    ]
  }
}
