export enum LinksUteisEnum{
  APPSTORE = 'https://apps.apple.com/br/app/daycoval/id1231632610',
  PLAYSTORE = 'https://daycoval.onelink.me/u5rP/institucional',
  APPDOWNLOADINSTITUCIONAL = 'https://daycoval.onelink.me/u5rP/institucional',
  APPDOWNLOADPVC = 'https://daycoval.onelink.me/u5rP/CreditoPVC',
  APPDOWNLOADINV = 'https://daycoval.onelink.me/u5rP/Investimentos',
  APPDOWNLOADCAMBIO = 'https://daycoval.onelink.me/u5rP/Cambio',
  APPDOWNLOADSERVICOS = 'https://daycoval.onelink.me/u5rP/Servicos',
  APPDOWNLOAD404 = 'https://daycoval.onelink.me/u5rP/Erro404',
  APPDOWNLOARODAPE = 'https://daycoval.onelink.me/u5rP/Rodape',
  PJDIGITAL = 'https://www.daycoval.com.br/PjDigital',
  ABRECONTASPF = 'https://www.daycovaldigital.com.br/AberturaContaPf/',
  ABRECONTASPFCONTINUAR = 'https://www.daycovaldigital.com.br/AberturaContaPf/continuar',
  DAYCONNECT = 'https://ecode.daycoval.com.br/',
  LPCONSIG = 'https://www.daycoval.com.br/atendimentodayane?assunto=refinanciarcontratar',
  RI = 'https://ri.daycoval.com.br/',
  RIEN = 'https://ri.daycoval.com.br/en',
  RIPOLITICAPRIVACIDADE = 'https://ri.daycoval.com.br/pt/politica-de-privacidade',
  BLOG = 'https://blog.daycoval.com.br/',
  DAYPAG = 'https://www.daypag.com.br/',
  REDIRECTWHATSAPPDAYANE = 'https://api.whatsapp.com/send?phone=5511991116583',
  AREARESTRITACAMBIO = 'https://app.daycovalcambio.com.br/',
  CANALCORRESPONDENTE = 'https://www.daycoval.com.br/canalcorrespondente/login',
  SEGUROVIAGEM = 'https://www.daycovalseguroviagem.com.br/',
  CGI = 'http://www.daycovalcgi.com.br/',
  CODIGODECONDUTA = 'https://ri.daycoval.com.br/pt/governanca-corporativa/politicas-institucionais',
  PORTALCAMBIO = 'http://portal.daycovalcambio.com.br/',
  EMPRESTIMODIGITAL = '#{EmprestimoDigital}',
  AGENTEAUTONOMO = 'https://agentedaycoval.com.br/',
  CAMPANHASDAYCOVAL = 'https://campanhasdaycoval.com.br/',
  CDNCMS = "https://cdn.daycoval.com.br/DayCms/",
  DAYANEREFINANCIARCONTRATAR = 'https://www.daycoval.com.br/atendimentodayane?assunto=refinanciarcontratar',
  REDIRECTWHATSAPPFALECONOSCO = 'https://api.whatsapp.com/send?phone=5511988625925',
  CORRETORACTVM = 'https://www.daycovalctvm.com.br'
}
